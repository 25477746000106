/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { useParams } from 'react-router';
import {
  Container,  Content, Header, Body,
} from './styles';

type LessonProps = {
    title: string;
    slug: string;
    availableAt: Date;
    type: 'live' | 'class';
}

const Lesson: React.FC<LessonProps> = (props) => {
  const { slug } = useParams<{ slug: string }>();


  const isActiveLesson = slug === props.slug;

  return (
    <Container to={`/classrooms/${props.slug}`}>
      <Content>
        <Header>
          <>
            <span>
              { isActiveLesson ? 'Em execução' : 'Iniciar'}
            </span>
            <span>
              AULA
            </span>
          </>
        </Header>
        <Body>
          {props.title}
        </Body>
      </Content>
    </Container>
  );
};

export default Lesson;
