import React, { useCallback, useEffect, useState } from 'react';
import { useHttp } from 'hooks/http';
import { Link } from 'react-router-dom';
import { DocumentReportIcon } from 'styles/icons';
import {
  Background, Container, Content, Title, Box, ContentWapper,
  Wapper,
} from './styles';

const Apply: React.FC = () => {
  const { httpGet } = useHttp();
  const [avaliation, setAvaliation] = useState<boolean>(false);

  const load = useCallback(async () => {
    const { status, data } = await httpGet('userclassrooms');
    if (status === 200) {
      if (data.length >= 8) {
        setAvaliation(true);
      }
    }
  }, [httpGet]);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <Container>
      <Background />
      <Content>
        <Title>Capacitação</Title>
        <Box>
          {
          avaliation && (
            <Link to="/assessmentv/uiouio" title="clique aqui" color="white">
              <ContentWapper>
                <h3>Avaliação Final: </h3>
                <DocumentReportIcon size={24} />
              </ContentWapper>
            </Link>
          )
        }
        </Box>
        <Wapper>
        <p>A capacitação é fundamental para garantir que os profissionais envolvidos possuam o conhecimento e as habilidades necessárias para promover a segurança e o bem-estar da comunidade. Este projeto, focado na prevenção de crimes e na proteção dos cidadãos, exige treinamentos contínuos para as equipes de segurança pública, incluindo policiais, agentes comunitários e outros profissionais. A capacitação envolve o domínio de técnicas de abordagem, gestão de situações de risco, uso de tecnologias de monitoramento e estratégias de mediação de conflitos, além de promover o entendimento das dinâmicas locais e da importância da interação com a comunidade. Com isso, o projeto visa não apenas aumentar a eficiência das ações de segurança, mas também fortalecer a confiança da população nas autoridades, criando um ambiente mais seguro e integrado.</p>
        </Wapper>
      </Content>
    </Container>
  );
};
export default Apply;
